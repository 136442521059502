import React from 'react';
import AstroLink from "../../lib/AstroLink";
import Img from "gatsby-image/withIEPolyfill"
import { slugify } from "../../lib/helpers";
import { ButtonArrowRight } from "../UI/Button";
import * as Markdown from 'react-markdown';

const PortfolioList = props => {
    const posts = props.data.allContentfulPortfolioDetail.nodes;
    const portfolioNodes = posts.sort((a, b) => new Date(b.entryDate).getTime() - new Date(a.entryDate).getTime());

    return (
        <section className="uk-padding-remove-bottom">
            {portfolioNodes.map((post, i) => (
                // post.entrySlug !== "get-lucky-in-the-year-of-the-rabbit" &&
                    <div key={post.entrySlug} className={`uk-grid uk-grid-xxsmall uk-grid-row-xxsmall uk-grid-match`}
                        data-uk-scrollspy={i !== 0 ? "cls: uk-animation-slide-bottom-medium" : null}>
                    <div className="uk-position-relative uk-overflow-hidden uk-width-3-5@m">
                        <div style={{ height: "100%" }} className="uk-height-min-large" data-uk-parallax="start: -20vh;scale: 1.125,1,1,1.05; blur:10,0,0,20; media: @s">
                            <Img
                                fluid={post.entryTileImage.localFile.childImageSharp.fluid}
                                alt={post.entryClient}
                                className="card__image__header"
                                objectFit="cover"
                                objectPosition="50% 50%"
                            />
                        </div>
                        <div className="uk-hidden@m uk-overlay uk-overlay-primary uk-position-bottom uk-light"
                            data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 300">
                            <div>
                                <div>
                                    {post.entryTopic ? <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                        <strong>&mdash;</strong> {post.entryClient}
                                    </p> : null}
                                    <p className="uk-text-medium uk-text-bold uk-text-astro-lighter uk-margin-remove-top">
                                        <AstroLink
                                            className="uk-link-text uk-link-reset"
                                            to={`/portfolio/${slugify(post.entryTopic)}/${slugify(post.industry)}/${post.entrySlug}/`}>
                                            {post.entryDescription}
                                        </AstroLink>
                                    </p>
                                    {post.entrySubtitle ? <Markdown source={post.entrySubtitle.subtitle} /> : null}
                                </div>
                                <div className="uk-margin-top">
                                    <ButtonArrowRight
                                        url={`/portfolio/${slugify(post.entryTopic)}/${slugify(post.industry)}/${post.entrySlug}/`}
                                        label="Read this"
                                        css={`cta-button uk-text-astro-lightest`}
                                        color={`rgba(255,255,255, 1)`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`grid__item uk-visible@m uk-width-expand uk-flex uk-flex-middle uk-flex-center ${i % 2 === 0 ? "uk-flex-first@m" : null}`}>
                        <div className="uk-padding uk-width-4-5@m" data-uk-scrollspy="cls:uk-animation-fade;delay: 400">
                            <div>
                                {post.entryTopic ? <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                                    <strong>&mdash;</strong> {post.entryClient}
                                </p> : null}
                                <p className="uk-text-large uk-text-bold uk-text-astro-darker uk-margin-remove-top">
                                    <AstroLink
                                        className="uk-link-text uk-link-reset"
                                        to={`/portfolio/${slugify(post.entryTopic)}/${slugify(post.industry)}/${post.entrySlug}/`}>
                                        {post.entryDescription}
                                    </AstroLink>
                                </p>
                                {post.entrySubtitle ? <Markdown source={post.entrySubtitle.subtitle} /> : null}
                            </div>
                            <div className="uk-margin-top">
                                <ButtonArrowRight
                                    url={`/portfolio/${slugify(post.entryTopic)}/${slugify(post.industry)}/${post.entrySlug}/`}
                                    label="Read this"
                                    css={`cta-button uk-text-astro-darker`}
                                    color={`rgba(44, 44, 43, 1)`} />
                            </div>
                        </div>
                    </div>
                </div>

            ))}
        </section>
    );
}

export default PortfolioList;
