import React from "react";


export const slugify = str => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const customColor = color => {

    if (color === 'undefined' || "none") return null;

    switch (color.toLowerCase()) {
        case "lightgrey":
            return "uk-background-muted";
        case "grey":
            return "uk-background-blood";
        case "darkgrey":
            return "uk-background-secondary uk-light";
        case "lightyellow":
            return "uk-background-mustard-lightest uk-text-mustard-darker";
        case "yellow":
            return "uk-background-mustard-light uk-text-mustard-darker";
        case "darkyellow":
            return "uk-background-mustard uk-light";
        case "lightgreen":
            return "uk-background-jungle-lightest uk-text-jungle-darker";
        case "green":
            return "uk-background-jungle-light uk-light";
        case "darkgreen":
            return "uk-background-jungle uk-light";
        case "lightred":
            return "uk-background-blood-lightest uk-text-blood-darker";
        case "red":
            return "uk-background-blood-light uk-light";
        case "darkred":
            return "uk-background-blood uk-light";
        case "lightblue":
            return "uk-background-gun-lightest uk-text-gun-darker";
        case "blue":
            return "uk-background-gun-light uk-light";
        case "darkblue":
            return "uk-background-gun uk-light";
        default:
            return "uk-background-blood";
    }
}

export const collectionDisplay = format => {
    switch (format) {
        case "carousel":
            return {
                carousel: true,
                count: null
            }
        case "slider":
            return {
                carousel: true,
                count: 1
            }
        default:
            return {
                carousel: false,
                count: null
            }
    }
}

export const textAlign = pos => {
    switch (pos) {
        case "Text Left":
            return "uk-flex-first@s";
        case "Text Right":
            return null;
        default:
            return "uk-flex-first@s";
    }
}

export const convertNumber = n => {
    let count = 5-n
    let color = ""
    switch(n) {
        case 0:
            count = "are five"
            color = "uk-text-primary"
            break;
        case 1:
            count = "are four"
            color = "uk-text-primary"
            break;
        case 2:
            count = "are three"
            color = "uk-text-warning"
            break;
        case 3:
            count = "are two"
            color = "uk-text-warning"
            break;
        case 4:
            count = "is one"
            color = "uk-text-danger"
            break;
        default:
            count = "are no"
            color = "uk-text-danger"
    }
    return <span className={`${color} uk-text-bold`}>{count}</span>
}