import React from "react";
import BackgroundImage from "gatsby-background-image";
import * as Markdown from 'react-markdown';

const FullscreenHeader = ({ media, content }) => {
  return (
    <div className="about__header uk-height-min-large uk-cover-container uk-overflow-hidden">
      {!media?.childImageSharp ?
        <>
        <div style={{ backgroundColor: "rgba(29, 38, 54, 0.7)" }} className="uk-position-cover disable-select" />
          <video
            style={{
                height: "100%",
                zIndex: "-1"
              }}
              className={`uk-cover uk-background-secondary`}
            playsInline
            autoPlay
            muted
            loop
            data-uk-cover
            data-uk-video="automute: true; autoplay: inview"
            src={media.publicURL ? media.publicURL : media } type="video/mp4">
          </video>
          <div className="uk-container uk-height-1-1 uk-position-relative uk-light">
            <div className={`uk-flex uk-flex-middle uk-height-1-1`}>
              <div className={`uk-container uk-flex-auto uk-text-left`} data-uk-parallax="y: 0,312; opacity:0;media: @s">
                <Markdown
                  source={content}
                  escapeHtml={false}
                />
              </div>
            </div>
          </div>
        </>
        :
        <BackgroundImage
          Tag="div"
          fluid={media.childImageSharp.fluid}
          preserveStackingContext={true}
          className="about__header uk-height-min-large uk-overflow-hidden"
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundPosition: `center center`,
            height: "100%",
            width: "100%"
          }}
          data-uk-parallax="scale: 1,1.25;blur: 0, 4px,5px;media: @s">
          <div style={{ backgroundColor: "rgba(29, 38, 54, 0.5)" }} className="uk-position-cover disable-select" />
          <div className="uk-container uk-container-large uk-height-1-1 uk-position-relative uk-light">
            <div className={`uk-width-3-5@m uk-flex uk-flex-bottom uk-height-1-1`}>
              <div className={`uk-padding uk-text-left uk-margin-xlarge-bottom`} data-uk-parallax="scale: 1,1.5;blur: 0,10px;media: @s">
                <Markdown
                  source={content}
                  escapeHtml={false}
                />
              </div>
            </div>
          </div>
        </BackgroundImage>

      }
    </div>
  );
};
export default FullscreenHeader;