import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet"
import Layout from "../Layout";
import PortfolioList from '../components/Portfolio/PortfolioList';
import FullscreenHeader from "../components/Content/FullscreenHeader";

const Portfolio = ({ location, ...props }) => {

  const title = `Brand. Experience. Code.`
  return (
    <Layout location={location}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Case studies of digital strategy, product design, UI/UX design, and technology development work." />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="Case studies of digital strategy, product design, UI/UX design, and technology development work." />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content="Case studies of digital strategy, product design, UI/UX design, and technology development work." />
      </Helmet>
      <FullscreenHeader
        media={props.data.allFile.nodes[0]}
        content={'<h1 class="h1__xlarge">Brand.<br />Experience.<br />Technology.</h1>'}
      />
      <div className="uk-margin-xxsmall"><PortfolioList data={props.data} /></div>
    </Layout>
  )
}

export default Portfolio;
export const pageQuery = graphql`
    query pageQuery {
      allFile(filter: {name: {eq: "mypic_portfolio"}}) {
        nodes {
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          internal {
            mediaType
          }
        }
      }
        allContentfulPortfolioDetail(sort: {
            order: DESC,
            fields: entryDate
        }) {
            nodes {
              entryCategory
              entryClient
              entryDate
              entryDescription
              entryHeadline
              entrySubHeadline {
                childMarkdownRemark {
                  rawMarkdownBody
                }
                entrySubHeadline
              }
              entryTitle
              entryTopic
              industry
              entrySlug
              entryHeroImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                  }
                }
              }
              entryTileImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                  }
                }
              }
              entryImages {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                  }
                }
              }
            }
          }
        
    }
`
